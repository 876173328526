import { IGeneralProps } from "~/components/OgTags";

const baseUrl = `https://${process.env.NEXT_PUBLIC_PAGE_URL || "diskagua.startapp.one"}`;

export const generalOgTags: IGeneralProps = {
	type: "website",
	title: "Disk Água",
	themeColor: "#131c2e",

	twitter: {
		type: "summary",
	},
	image: {
		url: `${baseUrl}/icon-1024.png`,
		type: "image/png",
		width: 1024,
		height: 1024,
	},
};
