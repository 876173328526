import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { BannerArrows } from "./BannerArrows";
import Image from "next/image";
import api from "~/resources/api";
import formatters from "~/resources/formatters";

interface IProps {
	banners: api.Banner[];
}

export const BannerCarousel: React.FC<IProps> = (props) => {
	const { banners } = props;
	const [isMobile] = useMediaQuery("(max-width: 720px)");
	const redirectToUrl = (url: string) => {
		window.location.replace(url);
	};

	return (
		<Box>
			<Carousel
				showStatus={false}
				showThumbs={false}
				showArrows
				showIndicators={false}
				renderArrowPrev={(clickHandler, hasBanner) => (
					<BannerArrows
						left={{ base: 2, sm: 5 }}
						clickHandler={clickHandler}
						hasBanner={hasBanner}
					/>
				)}
				renderArrowNext={(clickHandler, hasBanner) => (
					<BannerArrows
						right={{ base: 2, sm: 5 }}
						direction="right"
						clickHandler={clickHandler}
						hasBanner={hasBanner}
					/>
				)}
			>
				{banners.map((banner) => (
					<Box
						width="100%"
						h={{ lg: "60vh" }}
						key={banner.id}
						onClick={() => redirectToUrl(banner.url)}
						cursor="pointer"
					>
						<Image
							loading="eager"
							layout="responsive"
							priority
							width={isMobile ? banner.mobileImage.width : banner.image.width}
							height={isMobile ? formatters.getAspectRatioMobile(banner.mobileImage.width) : formatters.getAspectRatioWeb(banner.image.width)}
							quality={100}
							src={isMobile ? banner.mobileImage.url : banner.image.url}
							alt={banner.title}
						/>
					</Box>

				))}
			</Carousel>
		</Box>
	);
};
