import { IProps as IOgTagsProps } from "~/components/OgTags";
import { Routes } from "~/resources/routes";
import { generalOgTags } from "./generalOgTags";

const baseUrl = `https://${process.env.NEXT_PUBLIC_PAGE_URL || "diskagua.startapp.one"}`;

export const homeOgTags: IOgTagsProps = {
	...generalOgTags,
	title: "Disk Água | Distribuidores de água",
	description: "Disk Água é um site com informações sobre distribuidoras de água",
	themeColor: "#000000",
	keywords: [
		"Progressive web app",
		"Desenvolvimento na StartApp",
		"Distribuidor de água",
	],
	url: `${baseUrl}${Routes.index().href}`,
};
