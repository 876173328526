import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

interface IProps{
	nextPage: () => void;
	previousPage: () => void;
	hasNextPage: boolean;
	page: number;
}

export const Pagination: React.FC<IProps> = (props) => {
	const {
		nextPage,
		previousPage,
		hasNextPage,
		page,
	} = props;

	const onGoToPreviousPage = () => {
		window.scrollTo(0,0);
		previousPage();
	};
	const onGoToNextPage = () => {
		window.scrollTo(0,0);
		nextPage();
	};

	return (
		<Flex
			gap={2}
			justifyContent="center"
			alignItems="center"
			mt={5}
			color="primary.500"
		>
			{page === 0 ?
				<Button
					variant="ghost"
					_focus={{outline:"none"}}
					color="primary.500"
					disabled
					fontSize="20px"
				>
					<ChevronLeftIcon />
				</Button>
				:
				<Button
					fontSize="20px"
					onClick={onGoToPreviousPage}
					color="primary.500"
					variant="ghost"
					_focus={{outline:"none"}}
				>
					<ChevronLeftIcon />
				</Button>
			}
			<Text cursor="default" fontSize={20}>
				{page + 1}
			</Text>
			{hasNextPage ?
				<Button
					fontSize="20px"
					color="primary.500"
					variant="ghost"
					_focus={{outline:"none"}}
					onClick={onGoToNextPage}
				>
					<ChevronRightIcon />
				</Button>
				:
				<Button
					fontSize="20px"
					variant="ghost"
					color="primary.500"
					disabled
					_focus={{outline:"none"}}
				>
					<ChevronRightIcon />
				</Button>
			}
		</Flex>
	);
};
