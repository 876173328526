import React from "react";
import { Box, BoxProps, Heading, HeadingProps } from "@chakra-ui/react";

interface IProps extends BoxProps{
	title?: string;
	headingProps?: HeadingProps;
}

export const Section: React.FC<IProps> = (props) => {

	const { title, headingProps, children, ...rest } = props;

	return (
		<Box w="100%" {...rest} >
			<Heading
				color="primary.500"
				textAlign="start"
				fontSize={{ base: "lg", sm: "2xl", lg: "40px" }}
				{...headingProps}
			>
				{title}
			</Heading>
			{children}
		</Box>
	);
};
