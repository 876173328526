import React from "react";
import api from "~/resources/api";
import { Flex } from "@chakra-ui/react";
import { CardDistributor } from "~/components/CardDistributors";
import { EmptyList } from "../EmptyList";

interface IProps{
	distributors: api.Distributor[];
}

export const DistributorsComponent: React.FC<IProps> = (props) => {
	const { distributors } = props;

	return (
		<Flex
			direction="column"
			gap="20px"
			mt={10}
			px={{
				base: 3,
				md: 0,
			}}
		>
			<Flex
				direction="column"
				gap={5}
			>
				{!distributors.length ? (
					<EmptyList />
				) :	(
					<>
						{distributors.map((distributor, index: number)=>{
							return(
								<CardDistributor key={index} distributor={distributor} />
							);
						})}
					</>
				)}
			</Flex>
		</Flex>
	);
};
