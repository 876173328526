import React from "react";
import strings from "~/resources/strings";
import {
	Flex,
	Text,
	Image,
	Box,
} from "@chakra-ui/react";

export const EmptyList: React.FC = () => {
	const string = strings.components.cardPost.cardInfo;
	return (
		<Flex
			bg="gray.50"
			maxW="100%"
			flexDirection="row"
			cursor="pointer"
			minH={{ base:"100px", sm: "155px"}}
			h={{ base:"100px", sm: "auto"}}
			borderRadius={5}
		>
			<Image
				src={string.srcImage}
				alt={string.title}
				marginRight={{ base: 0, sm: 2 }}
				borderLeftRadius={5}
				width={{
					base: 28,
					sm: 40,
					md: "35%",
				}}
			/>
			<Flex
				color="secondary.600"
				padding={2}
				flexDirection="column"
				justifyContent="space-between"
				my={{ sm: 5 }}
			>
				<Box>
					<Text
						fontWeight="bold"
						fontSize="18px"
						color="secondary.500"
						isTruncated
					>
						{string.title}
					</Text>
					<Text
						fontSize="12px"
						isTruncated
					>
						{string.address}
					</Text>
				</Box>
				<Flex gap={1}>
					<Image
						alt="menu-icon"
						src="/tell.svg"
						cursor="pointer"
					/>
					<Text
						fontSize="12px"
						isTruncated
					>
						{string.tel}
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};
