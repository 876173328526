import React from "react";
import api from "~/resources/api";
import { Box, Flex, Text, Image, FlexProps } from "@chakra-ui/react";

interface ICardProps extends FlexProps {
	distributor: api.Distributor;
	onClick?: () => void;
}

export const CardDistributor: React.FC<ICardProps> = (props) => {
	const { distributor, onClick, ...rest } = props;
	return (
		<Flex
			bg="gray.50"
			maxW="100%"
			flexDirection="row"
			cursor="pointer"
			minH={{ base:"120px", sm: "155px"}}
			h={{ base:"100px", sm: "auto"}}
			borderRadius={5}
			onClick={onClick}
			{...rest}
		>
			<Image
				src={distributor.image.url}
				alt={distributor.name}
				marginRight={{ base: 0, sm: 2 }}
				borderLeftRadius={5}
				width={{
					base: 40,
					sm: 40,
					md: 215,
				}}
				maxH={40}
			/>
			<Flex
				color="secondary.600"
				padding={2}
				flexDirection="column"
				justifyContent="space-between"
				my={{ sm: 5, lg: 0 }}
			>
				<Box>
					<Text
						fontWeight="bold"
						fontSize="18px"
						color="secondary.500"
						isTruncated
					>
						{distributor.name}
					</Text>
					<Text
						fontSize="12px"
						isTruncated
					>
						{distributor.address}
					</Text>
				</Box>
				<Flex gap={1} mt={{ base: 5, lg: 0 }}>
					<Image
						alt="menu-icon"
						src="/tell.svg"
						cursor="pointer"
					/>
					<Text
						fontSize="12px"
						isTruncated
					>
						{distributor.phone}
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};
