import { IProps as IOgTagsProps } from "~/components/OgTags";
import { Routes } from "~/resources/routes";
import { generalOgTags } from "./generalOgTags";

const baseUrl = `https://${process.env.NEXT_PUBLIC_PAGE_URL || "diskagua.startapp.one"}`;

export const faqOgTags: IOgTagsProps = {
	...generalOgTags,
	title: "Disk Água | Perguntas Frequentes",
	description: "Aqui você encontra tudo sobre como funciona o Disk Água.",
	keywords: [
		"Como funciona",
		"FAQ",
		"Desenvolvimento na StartApp",
	],
	url: `${baseUrl}${Routes.faq().href}`,
};
