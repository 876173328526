import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box, Image, BoxProps } from "@chakra-ui/react";

type TDirection = "right" | "left";

interface IProps extends BoxProps {
	clickHandler: () => void;
	hasBanner: boolean;
	direction?: TDirection;
}

export const BannerArrows: React.FC<IProps> = (props) => {
	const { clickHandler, hasBanner, direction = "left", ...rest } = props;

	return (

		<Box
			cursor="pointer"
			pos="absolute"
			top="50%"
			zIndex={200}
			opacity={hasBanner ? 1 : 0.3}
			h={{ base: 5,  md: "3rem" }}
			w={30}
			onClick={clickHandler}
			{...rest}
		>
			<Image
				src={direction === "left" ?  "/left-arrow.svg" : "/right-arrow.svg"}
				h="100%"
				cursor="pointer"
				alt={direction}
			/>
		</Box>

	);
};
