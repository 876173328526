import { IProps as IOgTagsProps } from "~/components/OgTags";
import { Routes } from "~/resources/routes";
import { generalOgTags } from "./generalOgTags";

const baseUrl = `https://${process.env.NEXT_PUBLIC_PAGE_URL || "diskagua.startapp.one"}`;

export const aboutUsOgTags: IOgTagsProps = {
	...generalOgTags,
	title: "Disk Água | Sobre Disk Água",
	description: "Aqui você encontra tudo sobre o Disk Água.",
	keywords: [
		"Quem somos",
		"Sobre nós",
		"Desenvolvimento na StartApp",
	],
	url: `${baseUrl}${Routes.aboutUs().href}`,
};
