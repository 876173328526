import { IProps as IOgTagsProps } from "~/components/OgTags";
import { Routes } from "~/resources/routes";
import { generalOgTags } from "./generalOgTags";

const baseUrl = `https://${process.env.NEXT_PUBLIC_PAGE_URL || "diskagua.startapp.one"}`;

export const contactForDistributorsOgTags: IOgTagsProps = {
	...generalOgTags,
	title: "Disk Água | Seja Distribuidor Disk Água",
	description: "Aqui você encontra como se tornar distribuidor do Disk Água.",
	keywords: [
		"Cadastro de Distribuidor",
		"Seja Nosso Distribuidor",
		"Desenvolvimento na StartApp",
	],
	url: `${baseUrl}${Routes.contactForDistributors().href}`,
};
